import {EnvironmentType} from "./environment-type";

export const environment: EnvironmentType = {
  production: true,
  tenant: 'hesse',
  tenantDisplayName: 'Hesse Engineering - Schweißfachbetrieb',
  tenantDisplayNameShort: 'Hesse Engineering',
  api: 'https://shop.robertengel.io',
  currency: 'EUR',
  auth: {
    clientId: 'rZyOE1oegn8wHqNojkw8FmqfpPlXOzh6',
    tenant: 'shop-admin'
  }
};
