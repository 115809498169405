import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext, StateOperator, StateToken} from "@ngxs/store";
import {catchError, finalize, of, tap} from "rxjs";
import {convertError, RequestError, ResetErrors} from "./common";
import {TaxCategory} from "../api/model/taxCategory";
import {PaymentTaxCategoriesHttpService} from "../api/api/paymentTaxCategories.http.service";

export interface TaxCategoryStateModel {
  categories: TaxCategory[] | undefined;
  loading: boolean;
  error: RequestError | undefined;
}

export class LoadTaxCategories {
  static readonly type = '[Tax-Categories] Load tax categories';
}

export class CreateTaxCategory {
  static readonly type = '[Tax-Categories] Create tax category';

  constructor(
    readonly name: string,
  ) {
  }
}

export class DeleteTaxCategory {
  static readonly type = '[Tax-Categories] Delete tax category';

  constructor(
    readonly id: string,
  ) {
  }
}

export const TAX_CATEGORY_STATE_TOKEN = new StateToken<TaxCategoryStateModel>('taxCategoryState');

const addCategory: ((category: TaxCategory) => StateOperator<TaxCategoryStateModel>) = (category) => {
  return (state: Readonly<TaxCategoryStateModel>) => {
    return {
      ...state,
      categories: [
        ...state.categories || [],
        category
      ]
    }
  }
}

const removeCategory: ((id: string) => StateOperator<TaxCategoryStateModel>) = (id) => {
  return (state: Readonly<TaxCategoryStateModel>) => {
    return {
      ...state,
      categories: state.categories?.filter(elem => elem.id !== id)
    }
  }
}

@State({
  name: TAX_CATEGORY_STATE_TOKEN,
  defaults: {
    categories: undefined,
    error: undefined,
    loading: false,
  }
})
@Injectable()
export class TaxCategoryState {

  constructor(
    private readonly taxCategoriesService: PaymentTaxCategoriesHttpService,
  ) {
  }

  @Selector()
  static categories(state: TaxCategoryStateModel) {
    return state.categories;
  }

  @Selector()
  static loading(state: TaxCategoryStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: TaxCategoryStateModel) {
    return state.error;
  }

  @Action(ResetErrors, {cancelUncompleted: true})
  resetErrors(ctx: StateContext<TaxCategoryStateModel>) {
    ctx.patchState({error: undefined})
  }

  @Action(LoadTaxCategories, {cancelUncompleted: true})
  loadCategories(ctx: StateContext<TaxCategoryStateModel>, action: LoadTaxCategories) {
    if (ctx.getState().categories) {
      return;
    }
    return this.taxCategoriesService.getTaxCategories().pipe(
      tap(categories => ctx.patchState({categories})),
      catchError(err => {
        console.error('Failed loading categories.', err)
        return of([])
      }),
    )
  }

  @Action(CreateTaxCategory, {cancelUncompleted: false})
  createCategory(ctx: StateContext<TaxCategoryStateModel>, action: CreateTaxCategory) {
    ctx.patchState({
      error: undefined,
      loading: true,
    })

    return this.taxCategoriesService.postTaxCategory({
      createTaxCategoryRequest: {
        name: action.name,
      }
    }).pipe(
      tap({
        next: category => ctx.setState(addCategory(category)),
        error: error => ctx.patchState({error: convertError(error)}),
      }),
      finalize(() => ctx.patchState({loading: false})),
    );
  }

  @Action(DeleteTaxCategory, {cancelUncompleted: false})
  deleteCategory(ctx: StateContext<TaxCategoryStateModel>, action: DeleteTaxCategory) {
    ctx.patchState({
      error: undefined,
      loading: true,
    })

    return this.taxCategoriesService.deleteTaxCategories({taxCategoryId: action.id}).pipe(
      tap({
        next: () => ctx.setState(removeCategory(action.id)),
        error: error => ctx.patchState({error: convertError(error)}),
      }),
      finalize(() => ctx.patchState({loading: false})),
    );
  }
}
